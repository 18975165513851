// instore app id admin的id
// export const AppId: string = '30a30b50-19dc-44c7-adcc-eb9996da21d4'; // edtyos

// api server url 統一配置Api服務器的url
// export const ApiServer: string = 'https://api.uat.in-store.hk';
// export const ApiServer: string = 'http://api.dev.in-store.hk:84';
// api version for this app 統一配置Api版本號
// export const ApiVersion: string = 'V2';
// api Authorization 身份认真，用于后端识别客户端
// export const Authorization: string = 'MzBhMzBiNTAtMTlkYy00NGM3LWFkY2MtZWI5OTk2ZGEyMWQ0OmE5NTQ0OTI1LWIwNWItNGUwYS04MDZhLTZkNGMzMjQ2ZWQ5OQ==';
// admin server url, for login management platform 統一配置admin服務器
// export const AdminServer: string = 'http://admin.dev.in-store.hk:84';
// export const AdminServer: string = 'https://admin.uat.in-store.hk';

// url '/admin' auto open admin tab
// export const AutoOpenAdmin: Boolean = true;
// admin login url 統一配置admin登錄地址
// export const AdminLoginUrl: string =
//   AdminServer + '/default/ClientLogin/' + AppId;
// 前端默认配置
// export const FrontE: any = {
//   defaultLang: 'C',
//   defaultCurrency: 1,
//   mobileBuilding: true,
//   pcBuilding: false
// };
const all = require('./SysConst2.ts');
export const AppId: string = all.AppId;
export const ApiServer: string = all.ApiServer;
export const ApiVersion: string = all.ApiVersion;
export const Authorization: string = all.Authorization;
export const AdminServer: string = all.AdminServer;
export const AutoOpenAdmin: Boolean = all.AutoOpenAdmin;
export const AdminLoginUrl: string = all.AdminLoginUrl;
export const FrontE: any = all.FrontE;

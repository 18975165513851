import Vue from 'vue';
import Swiper from 'vue-awesome-swiper';
import App from './App.vue';
import router from './router';
import VueRouter from 'vue-router';
import store from './store';
import Cookie from 'js-cookie';
// import ElementUI from 'element-ui';
// import './element';
import { setRem } from './rem';
import MetaInfo from 'vue-meta-info';
// import { ValidationProvider } from 'vee-validate';
// 引入指令文件
import * as directives from '@/directives/index';
import elLoading from 'element-ui/packages/loading/src/directive';

// require styles
import 'swiper/dist/css/swiper.css';
// 引入css
import 'element-ui/lib/theme-chalk/index.css';

// 引进Axios
import axios from 'axios';
import vueAxios from 'vue-axios';
// // 引入多语言
// import VueI18n from 'vue-i18n';
import i18n from '@/lang';

import Auth from './sdk/common/Auth';
import ElementUI from 'element-ui';
Vue.use(ElementUI);
(() => {
  if (
    navigator.userAgent.indexOf('iPhone') !== -1 ||
    navigator.userAgent.indexOf('Android ') !== -1
  ) {
    Vue.prototype.userAgent = 'mobile';
    return import('@/assets/css/main_m.css');
  } else {
    Vue.prototype.userAgent = 'pc';
    return import('@/assets/css/main.css');
  }
})();
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push (location) {
//   return (originalPush.call(this, location) as any).catch(err => { console.log(err); });
// };
Vue.use(elLoading);
Vue.use(MetaInfo);
//  批量注册全局指令
Object.keys(directives).forEach(k => Vue.directive(k, directives[k]));
// ValidationProvider
// Vue.component('ValidationProvider', ValidationProvider);
Vue.use(Swiper);
// modified file 'node_module/_vue@2.6.10@vue/types/plugin.d.ts'
// Vue.use(ElementUI);
Vue.use(vueAxios, axios, i18n);
Vue.config.productionTip = false;
Auth.GetToken().then(
  () => {
    import('./init').then(async (result) => {
      Vue.use(result.default as any);
      let lang = Vue.prototype.$Storage.get('locale'); // || Vue.prototype.FrontE.defaultLang
      if (lang) {
        await Vue.prototype.$Api.member.setUILanguage(lang).then((result) => {
          i18n.locale = lang;
          Vue.prototype.$Storage.set('locale', lang);
          Cookie.set('uLanguage', lang);
        }).catch((error) => {
          console.log(error);
        });
      } else {
        lang = Vue.prototype.FrontE.defaultLang;
        i18n.locale = lang;
        Vue.prototype.$Storage.set('locale', lang);
        Cookie.set('uLanguage', lang);
      }
      return result;
    }).then(result => {
      Vue.prototype.root = new Vue({
        router,
        store,
        i18n,
        mounted () {
          if (Vue.prototype.userAgent === 'mobile') setRem();
        },
        render: h => h(App)
      }).$mount('#app');
    });
  }, () => { });

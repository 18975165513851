const ana = require('./ApiAndAdminServer');
const AppId = 'eb9321e6-d521-4529-81fd-ae5840306daf';
// let AdminServer = 'http://admin.dev.in-store.hk:84'; // DEV
// let AdminServer = 'https://admin.uat.intimex.hk'; // UAT
// let AdminServer = 'https://admin.uat.in-store.hk'; // Production
let AdminServer = ana.AdminServer;
let apiServer = ana.apiServer;
module.exports = {
  // instore app id admin的id
  AppId: AppId, // style3
  // api server url 統一配置Api服務器的url
  // ApiServer: 'https://api.uat.in-store.hk', // Production
  // ApiServer: 'https://api.uat.intimex.hk', // uat
  // ApiServer: 'http://api.dev.in-store.hk:84', // dev
  ApiServer: apiServer,
  // api version for this app 統一配置Api版本號
  ApiVersion: 'V2',
  // api Authorization 身份认真，用于后端识别客户端
  Authorization: 'ZWI5MzIxZTYtZDUyMS00NTI5LTgxZmQtYWU1ODQwMzA2ZGFmOmI2NTQ1Nzk1LTM3NDUtNDcwMC1iMTI3LWQwYmUyM2VkMDdjNA==',
  // admin server url, for login management platform 統一配置admin服務器

  AdminServer: AdminServer,
  // url '/admin' auto open admin tab
  AutoOpenAdmin: true,
  // admin login url 統一配置admin登錄地址
  AdminLoginUrl: AdminServer + '/default/ClientLogin/' + AppId,
  // 前端默认配置
  FrontE: {
    defaultLang: 'C',
    defaultCurrency: 1,
    mobileBuilding: false,
    pcBuilding: false,
    cmsSeoList: [],
    productSeoList: ['Home'],
    DevEnvironmental: false // 是否为DEV环境，true:是，false:否
  }
};

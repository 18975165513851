export default {
  lang: '繁',
  test: '测试',
  AddToCart: '加入购物车',
  changeLangSuccess: '语言切换成功！',
  changeCurSuccess: '货币切换成功！',
  Home: {
    WhatsApptext: '%E6%88%91%E6%83%B3%E6%9F%A5%E8%AF%A2%E4%B8%80%E4%B8%8B'
  },
  Input: {
    email: '请输入正确的邮箱',
    number: '请输入数字',
    input: '请输入',
    text: '',
    date: '请选择日期',
    password: '两次输入的密码不一致',
    textarea: '',
    search: '搜索'
  },
  Message: {
    Logout: '您尚未登陆或登陆超时，请重新登陆',
    Message: '消息',
    Confirm: '确认',
    Cancel: '关闭',
    SucceedInOperating: '操作成功',
    MemberInfo: '您的个人信息尚未完善，这有可能会影响您获取优惠',
    msg400: '400 系统繁忙!',
    msg401: '401 系統繁忙!',
    msg403: '403 系統繁忙!',
    msg404: '404 链接不存在!',
    msg500: '500 系統繁忙!',
    msg503: '503 系統繁忙!',
    msg502: '502 bad gataway!',
    SavedSuccessfully: '保存成功'
  },
  Account: {
    MemberInformation: '用户信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最爱',
    MyOrder: '我的订单',
    DeliveryAddress: '送货地址',
    Logout: '登出',
    Welcome: '欢迎!',
    SISA: '选择成功',
    MemberCentral: '会员中心'
  },
  product: {
    naCate1: '花艺',
    naCate2: '分类',
    ProductIntroduction: '简介',
    ProductCode: '产品编号',
    buy: '立即购买',
    addToCart: '加入购物车',
    favorite: '加入我的清单',
    priceTitle: '价格',
    countTitle: '数量',
    youWouldLike: '你可能喜欢',
    moreTips: '点击加载更多',
    pullTips: '下拉加载更多',
    none: '以上是全部产品',
    noneInCat: '当前目录无产品，请选择其他目录',
    noneInSearch: '搜索不到产品',
    notifyMe: '到货通知',
    purchasing: '采购中',
    comments: { title: '商品评论', none: '该商品暂无评论' },
    detail: '产品详情',
    overView: '产品简介',
    specification: '使用说明',
    attr: { 0: '尺寸', 1: '颜色' },
    SaleOut: '售罄',
    offSale: '已下架',
    Recommend: '会员推荐',
    Remarks: '备注',
    EnterRemaks: '请填写备注',
    up: '上一个',
    down: '下一个',
    logouted: '您尚未登陆！',
    loginow: '请先登陆或注册',
    successInAdding: '添加成功',
    successInRemoving: '取消成功',
    replyFromShopkeeper: '店主回复',
    per: '弟',
    page: '页'
  },
  pyramidSelling: {
    ps: '传销'
  },
  Shoppingcart: {
    ShoppingcartTitle: '购物车',
    Merchant: '商家',
    Product: '产品',
    Quantity: '数量',
    Price: '价格',
    Total: '商品总价',
    CheckoutList: '确认订单',
    Checkout: '安全结算',
    goTOCart: '前往购物车',
    vat: '包含增值税',
    None: '购物车里空空如也，现在去购买？',
    more: '更多',
    hide: '收起'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '会员登入',
    NewUser: '新用户',
    RegNow: '现在注册',
    ForgetPwd: '忘记密码?',
    RememberMe: '记住我',
    FaceBookUse: '使用FaceBook帐号登陆',
    UserName: '登陆用户名/邮箱地址',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确邮箱地址',
    LoginPwd: '请输入密码',
    LoginoldPwd: '密码不正确',
    Loginerror: '帐号或密码不正确'
  },
  Register: {
    ResetPwd: '重设密码',
    or: '或',
    RegisterTitle: '注册会员',
    UserEmail: '邮箱',
    UserRegPassword: '密码',
    UserNewPassword: '新密码',
    UserRegTips: '最少6个由字母或数字组成的字符',
    UserConfirmPassword: '确认密码',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '联系电话',
    UserBirthDate: '出生日期',
    UserGender: '性别',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '语言',
    UserLanguageE: '英文',
    UserLanguageT: '繁体',
    UserLanguageS: '简体',
    RegisterBtn: '注册',
    RegisterAgree: '我同意购买条款和使用条款',
    RegisterEmail: '邮箱不能为空',
    RegisterEmail01: '请输入正确的邮箱格式',
    RegisterPassword: '请输入密码',
    RegisterNewPassword: '请再次输入密码',
    RegisterNewPassword01: '两次输入的密码不一致',
    RegisterFirstName: '请输入您的姓名',
    RegisterLastName: '请输入您的名称',
    RegisterAgree_check: '请勾选',
    RegisterMobile: '请输入您的手提电话',
    Succeed: '注册成功',
    Succeed1: '恭喜你注册成功',
    Login: '跳转登陆'
  },
  DeliveryAddress: {
    AddAddress: '新增地址',
    ChooseDeliveryAddress: '选择送货地址',
    EditBtn: '编辑',
    DeleteBtn: '删除',
    AddDeliveryAddress: '添加新的收货地址',
    PostalCode: '邮政编码',
    Area: '地区',
    Province: '省份',
    District: '区份',
    Address: '地址',
    DefaultAddress: '设为默认送货地址?',
    SaveBtn: '保存',
    ResetBtn: '重设',
    CloseBtn: '关闭',
    YesBtn: '是',
    NoBtn: '否',
    AddFirstName: '名字',
    AddLastName: '姓氏',
    UserContactNumber: '联系电话',
    Mobile: '手提电话',
    Addressprovince: '请选择省份',
    AddressArea: '请选择地区',
    Detail: '街道/屋苑/楼层/室'
  },
  MyCoupon: {
    MyCoupon: '我的优惠券',
    Coupon: '优惠券',
    PeriodOfValidity: '有效期',
    NoCoupon: '暂无优惠券',
    CouponStatus: '优惠券状态',
    SelectCouponStatus: '请选择优惠券状态',
    TotalNum: '共有',
    Piece: '张',
    NotUse: '可用',
    Used: '不可用',
    Among: '其中',
    ALL: '所有'
  },
  MyFavorite: {
    MyFavorite: '我的最爱',
    LearnMore: '了解更多',
    RemoveProduct: '移除产品',
    RemoveSuccess: '移除成功'
  },
  Notification: {
    MyMessages: '我的信箱',
    MsgList: '消息列表',
    TotalRecQtyWithSymbol: '总记录数 ：',
    UnreadWithSymbol: '未读记录数 ：',
    MsgUnreadOnly: '只看未读消息',
    MsgMarkAsRead: '标记为已读',
    CheckAllOrNot: '全选/反选',
    MsgSender: '发信人',
    MsgSubject: '主题',
    MsgSenderDate: '发信时间',
    MsgReadStatus: '状态',
    Details: '了解详情',
    More: '更多',
    Back: '返回',
    MsgContent: '内容',
    Send: '发送',
    UnRead: '未读',
    Aread: '已读'
  },
  Forgetpassword: {
    ForgetPassword: '忘记密码',
    ResetPwdTips: '请进入你绑定的邮箱，点击下一步按钮进行重置操作',
    LoginNameEmail: '用户名/邮箱',
    NextStep: '下一步',
    ResetPwdSuccess: '重设密码依法送到您的邮箱，请查看。',
    NotEmail: '收不到重设密码电邮',
    ToReEnter: '按此重新填写',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确的邮箱地址'
  },
  MemberInfo: {
    MemberInfoTitle: '用户信息',
    UserAccount: '用户账号',
    ModifyPassword: '修改密码',
    EnterUserName: '请输入名字',
    EnterUserLastName: '请输入姓氏',
    EnterUserPhone: '请输入联系电话',
    EnterUserBrithDate: '请选择出生日期',
    EnterOldPwd: '请输入密码',
    EnterNewPwd: '请输入新密码',
    EnterComfirmPwd: '请输入确认密码',
    EnterNumber: '请输入数字',
    PwdNotCorrect: '两次密码不一致'
  },
  Order: {
    OrderTitle: '我的订单',
    OrderDetail: '订单详情',
    OrderPay: '立即支付',
    OrderCancel: '订单取消',
    OrderComplete: '订单完成',
    OrderClose: '订单关闭',
    CartSubtotal: '小计',
    DeliveryCharge: '运输费用',
    TotalPrice: '总价',
    OrderStatus: '订单状态',
    OrderDateTime: '订单日期&时间 ',
    OrderNumber: '订单编号',
    OrderSearch: '订单搜索',
    EnterProductName: '请输入产品名称',
    Items: '项',
    OrderComment: '评价',
    PromotionCode: '促销码',
    RecommendationCode: '会员推荐码',
    CommodityPrice: '商品总价',
    Full: '满',
    Minus: '减',
    Hit: '减',
    Precent: '%',
    Discount: '折扣',
    Cancel: '取消'
  },
  Concessions: {
    Cannot: '优惠券与优惠码不可同时使用'
  },
  Payment: {
    ReturnHomePage: '返回首页',
    PayStatus: '支付状态',
    PayFailTips: '本次支付失敗，请检查您的支付户口后重新尝试。',
    PaySuccessTips: '本次支付成功'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即报名',
    ReturnHomePage: '返回首页',
    OrderDetails: '订单详情'
  },
  CheckOut: {
    CompanyAddress: '门店地址',
    inputPromotionCode: '请输入优惠码',
    Ordersummary: '订单摘要',
    Input: '请输入',
    expiryDate: '有效日期',
    awsl: 'awsl',
    expressError: '快递异常！请选择其他快递。',
    pay: '支付',
    CheckOutTitle: '确认订单',
    Merchant: '商家',
    Product: '产品',
    Qty: '数量',
    Price: '价钱',
    SplitDelivery: '拆单',
    ShippingMethod: '送货方式',
    PleaseSelect: '请选择',
    Selected: '已选择',
    Name: '名字',
    Phone: '电话',
    Address: '地址',
    Pickup: '自取',
    Change: '新增或更改地址',
    DeliveryCharge: '运输费用',
    SubTotal: '商品小计',
    PayBy: '支付方式',
    Couponcode: '优惠码',
    confirm: '确认',
    Total: '共',
    BacktoShoppingCart: '返回购物车',
    Last: '上一页',
    Checkout: '确认订单',
    Next: '下一页',
    Delivery: '送货方式与运费',
    SelectDeliveryAddress: '选择送货地址',
    LastName: '姓氏',
    Edit: '更改地址',
    Delete: '删除地址',
    Select: '选取',
    NewAddress: '添加新的收货地址',
    PickUp: '自取',
    FirstName: '名字',
    ZipCode: '邮政编码',
    Mobile: '手机',
    Country: '地区',
    Province: '地区/省份',
    City: '区份',
    Close: '关闭',
    PickupDate: '自取日期',
    PickupTime: '自取时间',
    Morning: '早上',
    Afternoon: '下午',
    PickAddressError: '自提信息必须完整',
    AddressLock: '地址已被选中不可删除',
    CompanyName: '门店',
    CompanyPhone: '门店电话',
    PickupAddress: '自取地址',
    Save: '保存',
    PleaseChoose: '请选择',
    PleaseSelectdate: '请选择日期',
    PleaseSelecttime: '请选择时间',
    ConfirmationOfOrder: '确认订单',
    Concessions: '优惠信息',
    PaymentMethod: '支付方式',
    Complete: '完成',
    OrderPreview: '订单预览',
    CandP: '优惠券与促销码不能同时使用',
    promotionCodeError: '请输入正确的优惠码',
    Use: '使用',
    As: '作为',
    PaymentMethodError: '支付方式尚未选择！'
  },
  Address: {
    Country: '请选择国家',
    Province: '请选择省份',
    Address: '请填写详细地址'
  },
  MemberPoints: {
    MemberPoints: '会员积分',
    PointsDetail: '积分明细',
    PointsIncome: '积分收入',
    PointsSpending: '积分支出',
    OrderTime: '订单时间',
    OrderNo: '订单编号',
    Income: '收入',
    Spending: '支出',
    IncomeSpending: '收入/支出',
    DetailedDescription: '详细说明',
    TotalPoints: '总积分',
    Hello: '您好',
    Equals: '等于',
    Integral: '积分',
    PointsTips: '积分使用常见问题',
    PointsType: '积分类别',
    Points: '积分',
    PointsMode: 'PointsMode'
  },
  Cms: {
    AboutUS: '关于我们'
  },
  promotion: {
    enter: '点击进入',
    main: '天才兒童最佳之選',
    monterssori: {
      title1: '蒙特梭利教學法',
      title2: '培養幼兒自覺主動的學習和探索精神',
      banner1: '做自己的老師',
      banner2: '讓兒童自發性地主動學習',
      banner3: '自己構建完善的人格'
    }
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上传文件(jpg, png, pdf)',
    UploadingText: '正在上传',
    ConfirmText: '确定',
    UploadSuccessfulText: '上传成功',
    UploadFailText: '上传失败',
    NoFileText: '没有选中文件',
    UploadLengthText: '最多只能上传{0}档案',
    UploadSizeText: '最多只能上传{0}M',
    PleaseSelect: '请选择',
    SubRecord: '提交记录',
    ReferenceNum: '参考编号',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '请选择支付方式'
  }
};

export default class Currency {
    public static Icons = ['', '$', '$', '￥', '¥']
    private _Code !: string;
    public get Code () : string {
      return this._Code;
    }
    public set Code (v : string) {
      this._Code = v;
    }
    private _Icon !: string;
    public get Icon () : string {
      return this._Icon;
    }
    public set Icon (v : string) {
      this._Icon = v;
    }
    private _Desc !: string;
    public get Desc () : string {
      return this._Desc;
    }
    public set Desc (v : string) {
      this._Desc = v;
    }

    private _Id : number;
    public get Id () : number {
      return this._Id;
    }
    public set Id (v : number) {
      this._Id = v;
      if (v === 1) {
        this._Code = 'HKD';
        this._Name = 'HKD';
        this._Icon = '$';
      } else if (v === 2) {
        this._Code = 'USD';
        this._Name = 'USD';
        this._Icon = '$';
      } else if (v === 3) {
        this._Code = 'RMB';
        this._Name = 'RMB';
        this._Icon = '￥';
      } else if (v === 4) {
        this._Code = 'PLN';
        this._Name = 'PLN';
      }
    }

    private _Name !: string;
    public get Name () : string {
      return this._Name;
    }
    public set Name (v : string) {
      this._Name = v;
    }
    constructor (_Id:number = 1, _Code:string = 'HKD', _Desc:string = '', _Name:string = 'HKD') {
      // this._Code = _Code;
      // this._Desc = _Desc;
      this._Id = _Id;
      // this._Name = _Name;
    }
}

import Vue from 'vue';
import VueRouter from 'vue-router';
import storage from './sdk/common/Storage';
import Auth from './sdk/common/Auth';
import i18n from '@/lang';
// import Home from './views/Home.vue';
// let userAgent:string = '';
// if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('Android ') !== -1) {
//   userAgent = 'mobile';
// } else {
//   userAgent = 'pc';
// }
Vue.use(VueRouter);

export const pRoutes = [
  // {
  //   path: '*',
  //   name: 'regnpay',
  //   component: () =>
  //     import('./views/pc/InsRegAndPay.vue')
  // },
  {
    path: '/building',
    name: 'building',
    component: () => import(/* webpackChunkName: "main" */ './components/service/InsBuilding.vue'),
    children: []
  },
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */ './platform/pc/index.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */'./views/pc/InsHome.vue')
      },
      {
        path: '/product/detail/:id',
        name: 'ProductsDetail',
        component: () =>
          import(/* webpackChunkName: "product" */ './views/pc/InsProductDetail.vue')
      },
      {
        path: '/product/search/:key?',
        name: 'productSearch',
        component: () =>
          import(/* webpackChunkName: "product" */ './views/pc/InsProductSearch.vue'),
        props: (route) => ({
          attrs: route.query.attrs,
          catalogs: route.query.catalogs,
          type: route.query.type
        })
      },
      {
        path: '/product/cat/:catId?',
        name: 'product',
        component: () =>
          import(/* webpackChunkName: "product" */ './views/pc/InsProductCat.vue')
      },
      {
        path: '/cms/content/:id',
        name: 'cmsContent',
        component: () =>
          import(
            /* webpackChunkName: "cms" */ './components/business/pc/cms/InsCmsContent.vue'
          )
      },
      // 会员中心路由开始
      {
        path: '/memberCentra/:location?',
        name: 'memberCentra',
        component: () => import(/* webpackChunkName: "account" */ '@/components/business/pc/account/InsMemberCentra.vue')
      },
      {
        path: '/account/memberCentral',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        name: 'memberCentra',
        component: () => import(/* webpackChunkName: "account" */ '@/components/business/pc/account/InsMemberCentral.vue')
      },
      {
        path: '/account/login',
        name: 'login',
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/pc/account/InsLoginN.vue'
          )
      },
      {
        path: '/account/register',
        name: 'register',
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/pc/account/InsLoginN.vue'
          )
      },
      {
        path: '/account/registerSuccess',
        name: 'registerSuccess',
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/pc/account/InsRegisterSuccess.vue'
          )
      },
      // {
      //   path: '/account/myFavorite',
      //   name: 'myFavorite',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/pc/account/InsMyFavorite.vue'
      //     )
      // },
      // {
      //   path: '/account/deliveryAddress',
      //   name: 'deliveryAddress',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/pc/account/InsDeliveryAddress.vue'
      //     )
      // },
      // {
      //   path: '/account/myCoupon',
      //   name: 'myCoupon',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/pc/account/InsMyCoupon.vue'
      //     )
      // },
      // {
      //   path: '/account/notification',
      //   name: 'notification',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/pc/account/InsNotification.vue'
      //     )
      // },
      {
        path: '/account/shoppingcart',
        name: 'shoppingcart',
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ './views/pc/InsShoppingCart.vue'
          )
      },
      {
        path: '/account/forgetPassword',
        name: 'forgetPassword',
        // meta: {
        //   requiresAuth: true // 是否进行登录验证
        // },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/pc/account/InsForgetPassword.vue'
          )
      },
      {
        path: '/account/checkout',
        name: 'checkout',
        meta: {
          requiresAuth: true, // 是否进行登录验证
          onlyFrom: '/account/shoppingcart'
        },
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ './views/pc/InsCheckoutN.vue'
          )
      },
      {
        path: '/account/points',
        name: 'points',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/pc/account/InsPoints.vue'
          )
      },

      // {
      //   path: '/account/orderList',
      //   name: 'OrderList',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "account" */ './components/business/pc/account/InsOrderList.vue'
      //     )
      // },
      {
        path: '/account/orderDetail/:id',
        name: 'orderDetail',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/pc/account/InsOrderDetail.vue'
          )
      },
      // {
      //   path: '/order/detail/:id',
      //   name: 'orderDetail',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/pc/account/InsOrderDetail.vue'
      //     )
      // },
      {
        path: '/account/orderComment/:id',
        name: 'orderComment',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/pc/account/InsAddComments.vue'
          )
      },
      // {
      //   path: '/account/orderComplete/:id',
      //   name: 'OrderComplete',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "account" */ './components/business/pc/account/InsOrderComplete.vue'
      //     )
      // },
      {
        path: '/account/completeCheckout/:id',
        name: 'completeCheckout',
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/pc/account/InsCompleteCheckout.vue'
          )
      },
      // 付款状态
      {
        path: '/Payment/PaySuccess/:id',
        name: 'paymentResult',
        component: () =>
          import(
          /* webpackChunkName: "payment" */ './views/pc/InsPaySuccess.vue'
          )
      },
      {
        path: '/payment/PayFail/:id',
        name: 'paymentFail',
        component: () =>
          import(
          /* webpackChunkName: "payment" */ './views/pc/InsRAPPayFail.vue'
          )
      },
      {
        path: '/regnpay/form/:id',
        name: 'regnpay',
        component: () =>
        import(
          /* webpackChunkName: "rnp" */ './views/pc/InsRegAndPay.vue'
        )
      },
      {
        path: '/regnpay/result/:id',
        name: 'regnpayResult',
        component: () =>
          import(
          /* webpackChunkName: "rnp" */ './views/pc/InsRegAndPayResult.vue'
          )
      },
      {
        path: 'admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "main" */'@/views/pc/InsAdmin.vue')
      },
      {
        path: '/account/ResetPwd/:id/:code',
        name: 'ResetPwd',
        meta: {
          requiresAuth: false // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/pc/account/InsResetPSW.vue'
          )
      }
    ]
  }
];

export const mRoutes = [
  // {
  //   path: '*',
  //   name: 'regnpay',
  //   component: () =>
  //     import(
  //       './views/pc/InsRegAndPay.vue'
  //     )
  // },
  {
    path: '/building',
    name: 'building',
    component: () => import(/* webpackChunkName: "main" */'./components/service/InsBuilding.vue'),
    children: []
  },
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */'./platform/mobile/index.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */'./views/mobile/InsHome.vue')
      },
      {
        path: '/product/detail/:id',
        name: 'ProductsDetail',
        component: () =>
          import(
            /* webpackChunkName: "product" */ './views/mobile/InsProductDetail.vue'
          )
      },
      {
        path: '/product/search/:key',
        name: 'productSearch',
        component: () =>
          import(/* webpackChunkName: "product" */ './views/mobile/InsProductSearch.vue')
      },
      {
        path: '/product/cat/:catId?',
        name: 'productCat',
        component: () =>
          import(/* webpackChunkName: "product" */ './views/mobile/InsProductCat.vue')
      },
      // 会员中心路由开始
      {
        path: '/account/login',
        name: 'login',
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/mobile/account/InsLoginN.vue'
          )
      },
      {
        path: '/account/register',
        name: 'register',
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/mobile/account/InsLoginN.vue'
          )
      },
      {
        path: '/account/registerSuccess',
        name: 'registerSuccess',
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/mobile/account/InsRegisterSuccess.vue'
          )
      },
      {
        path: '/account/memberCentral',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        name: 'memberCentra',
        component: () => import(/* webpackChunkName: "mobileAccount" */'@/components/fsBusiness/mobile/account/FsMemberCentral.vue')
      },
      // {
      //   path: '/account/myFavorite',
      //   name: 'myFavorite',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/mobile/account/InsMyFavorite.vue'
      //     )
      // },
      // {
      //   path: '/account/deliveryAddress',
      //   name: 'deliveryAddress',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/mobile/account/InsDeliveryAddress.vue'
      //     )
      // },
      // {
      //   path: '/account/myCoupon',
      //   name: 'myCoupon',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/mobile/account/InsMyCoupon.vue'
      //     )
      // },
      // {
      //   path: '/account/notification',
      //   name: 'notification',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/mobile/account/InsNotification.vue'
      //     )
      // },
      {
        path: '/account/forgetPassword',
        name: 'forgetPassword',
        // meta: {
        //   requiresAuth: true // 是否进行登录验证
        // },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/mobile/account/InsForgetPassword.vue'
          )
      },
      // {
      //   path: '/account/points',
      //   name: 'points',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/mobile/account/InsPoints.vue'
      //     )
      // },

      // {
      //   path: '/account/orderList',
      //   name: 'OrderList',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/mobile/account/InsOrderList.vue'
      //     )
      // },
      {
        path: '/account/orderDetail/:id',
        name: 'orderDetail',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/mobile/account/InsOrderDetail.vue'
          )
      },
      // {
      //   path: '/order/detail/:id',
      //   name: 'orderDetail',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/mobile/account/InsOrderDetail.vue'
      //     )
      // },
      // {
      //   path: '/account/orderComplete/:id',
      //   name: 'OrderComplete',
      //   meta: {
      //     requiresAuth: true // 是否进行登录验证
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ './components/business/mobile/account/InsOrderComplete.vue'
      //     )
      // },
      {
        path: '/account/orderComment/:id',
        name: 'orderComment',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/mobile/account/InsAddComments.vue'
          )
      },
      {
        path: '/account/completeCheckout/:id',
        name: 'completeCheckout',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/mobile/account/InsCompleteCheckout.vue'
          )
      },
      {
        path: '/account/shoppingcart',
        name: 'shoppingcart',
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ './views/mobile/InsShoppingcart.vue'
          )
      },
      {
        path: '/account/checkout',
        name: 'checkout',
        meta: {
          requiresAuth: true, // 是否进行登录验证
          onlyFrom: '/account/shoppingcart'
        },
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ './views/mobile/InsCheckoutN.vue'
          )
      },
      {
        path: '/account/complete/:id',
        name: 'complete',
        meta: {
          requiresAuth: true, // 是否进行登录验证
          onlyFrom: '/account/checkout'
        },
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ './components/business/mobile/account/InsCompleteCheckout.vue'
          )
      },
      {
        path: '/Payment/PaySuccess/:id',
        name: 'paymentResult',
        component: () =>
          import(
          /* webpackChunkName: "payment" */ './views/mobile/InsPaySuccess.vue'
          )
      },
      {
        path: '/payment/PayFail/:id',
        name: 'paymentFail',
        component: () =>
          import(
          /* webpackChunkName: "payment" */ './views/mobile/InsRAPPayFail.vue'
          )
      },
      {
        path: '/cms/content/:id',
        name: 'cmsContent',
        component: () =>
          import(
            /* webpackChunkName: "cms" */ './components/business/mobile/cms/InsCmsContent.vue'
          )
      },
      {
        path: '/regnpay/form/:id',
        name: 'regnpay',
        component: () =>
        import(
          /* webpackChunkName: "rnp" */ './views/pc/InsRegAndPay.vue'
        )
      },
      {
        path: '/regnpay/result/:id',
        name: 'regnpayResult',
        component: () =>
          import(
          /* webpackChunkName: "rnp" */ './views/pc/InsRegAndPayResult.vue'
          )
      },

      {
        path: '/account/ResetPwd/:id/:code',
        name: 'ResetPwd',
        meta: {
          requiresAuth: false // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ './components/business/mobile/account/InsResetPSW.vue'
          )
      }
    ]
  }
];

let routers;

if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
  routers = mRoutes;
} else {
  routers = pRoutes;
}

const router = new VueRouter({
  mode: 'history', // history
  // base: '/edtoys/',
  routes: routers
});

export default router;
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) { // 如果未匹配到路由
    // SEO判斷處理
    let lang = to.path.toLowerCase().match(/\/en|\/xc|\/tc/i);

    if (lang) {
      let transLang;
      switch (lang[0]) {
        case '/en':
          transLang = 'E';
          break;
        case '/xc':
          transLang = 'S';
          break;
        case '/tc':
          transLang = 'C';
          break;
      }

      i18n.locale = transLang;

      next({
        path: to.path.toLowerCase().replace(lang[0], '')
      });
    } else {
      from.name ? next({ name: from.name }) : next('/'); // 如果上級也未匹配到路由則跳轉首頁，如果上級能匹配到則轉上級路由
    }
  }
  if (from.name) {
    Vue.prototype.$ShowLayer();
    setTimeout(() => {
      Vue.prototype.$HiddenLayer();
    }, 1000);
  }
  next();
});
router.beforeEach((to, from, next) => {
  // if (to.fullPath === '/') {
  //   next({
  //     path: '/regnpay/form/orderform'
  //   });
  //   return;
  // }

  setTimeout(function () {
    if (to.path === '/account/login') {
      if (storage.get('isLogin')) {
        next({ path: '/' });
      } else next();
    } else {
      if (to.meta.requiresAuth && !storage.get('isLogin')) {
        // router.push({ path: '/account/login' });
        // window.location.href = '/account/login';
        window.location.href = ('/account/login?returnurl=' + window.location.pathname);
        // next({
        //   path: '/account/login',
        //   query: {
        //     returnurl: to.path
        //   }
        // });
      } else if (to.meta.requiresAuth && storage.get('timeout') && storage.get('timeout') < new Date().getTime()) {
        window.location.href = ('/account/login?returnurl=' + window.location.pathname);
        // Vue.prototype.$Login(function () { next(); });
      } else {
        next();
      }
    }
  }, 200);
});
router.beforeEach((to, from, next) => {
  if (to.meta.onlyFrom) {
    if (to.meta.onlyFrom === from.path) next();
    else next(to.meta.onlyFrom);
  } else {
    next();
  }
});
/**
 * 目前蒙层已经不会自动关闭，请在页面适当的时机自行打开。this.$HiddenLayer();
 */
/**
 * 目前所有路由均被拦截加上蒙层，防止页面抖动过大，有需要的小伙伴可以调用：this.$HiddenLayer()，在你觉得合适的时机关闭蒙层（目前我在本文档的159行设置计时器2秒后关闭蒙层）。
 * 如遇ts检测this.$HiddenLayer报找不到改属性或变量，请直接调用Vue.prototype.$HiddenLayer()。其实二者是相同的，但是TS貌似检测不到对象的原型，所以报错。
 * 关于layer配置在./init.ts上，如有需要可以自行修改，或者一起讨论如何修改。
 * */
